<template>
  <v-card elevation="1">
    <v-toolbar height="45" flat color="accent" light>
      <v-toolbar-title class="white--text"
        ><v-icon v-text="icons.mdiAccountMultiple" class="white--text" left></v-icon
        >{{ $route.meta.title }}</v-toolbar-title
      >
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs color="accent">
      <v-tab>
        <v-icon v-text="icons.mdiPencil" left></v-icon>
        Vendor Info
      </v-tab>
      <v-tab>
        <v-icon v-text="icons.mdiInformation" left></v-icon>
        Additional Info
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="vendor.vendor_name"
                    color="secondary"
                    label="Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="vendor.url"
                    color="secondary"
                    label="URL (Optional)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="vendor.account_number"
                    color="secondary"
                    label="Account Number"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="vendor.first_name"
                    color="secondary"
                    label="First Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="vendor.last_name"
                    color="secondary"
                    label="Last Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="vendor.email"
                    color="secondary"
                    label="Email (Optional)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <div v-for="(phone, index) in vendor.phones" :key="index">
                    <v-select
                      v-model="phone.type"
                      color="secondary"
                      dense
                      outlined
                      label="Type"
                      :items="items"
                    ></v-select>
                    <input type="hidden" v-model="phone.module_name" readonly />
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div v-for="(phone, index) in vendor.phones" :key="index">
                    <v-text-field
                      :append-icon="phone.default ? icons.mdiPhone : icons.mdiPhoneRemove"
                      v-model="phone.tel_number"
                      color="secondary"
                      dense
                      label="Phone Number"
                      outlined
                      @click:append="phone.default = !phone.default"
                    ></v-text-field>
                    <input type="hidden" v-model="phone.default" />
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn class="mt-n10" color="primary" plain @click.prevent="addphone"
                    ><v-icon v-text="icons.mdiPlus" size="20" class="mr-1"></v-icon>Add another phone number</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="vendor.address1"
                    color="secondary"
                    label="Address 1"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="vendor.address2"
                    color="secondary"
                    label="Address 2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="vendor.town_city"
                    dense
                    label="Town/City"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="vendor.province"
                    dense
                    label="Province"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="vendor.postal_code"
                    dense
                    label="Postal Code"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" rounded @click.prevent="updateVendor">
              <v-icon v-text="icons.mdiContentSave" right dark></v-icon>
              <span>Save</span>
            </v-btn>
            <v-btn color="secondary" rounded @click.prevent="$router.push({ name: 'vendor' })">
              <v-icon v-text="icons.mdiClose" right dark></v-icon>
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
  
  <script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  mdiAccountTie,
  mdiPencil,
  mdiPhone,
  mdiPhoneRemove,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiClose,
  mdiAccountMultiple,
} from '@mdi/js'
export default {
  components: {},

  created: function () {
    this.getVendors()
  },
  data() {
    return {
      vendor: {
        vendor_name: '',
        url: '',
        account_number: '',
        email: '',
        phones: [
          {
            phone_id: '',
            type: '',
            module_name: 'vendor',
            tel_number: '',
            default: '',
          },
        ],
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        town_city: '',
        province: '',
        postal_code: '',
      },
      items: ['Mobile', 'Work', 'Home', 'Other'],
      steps: 1,
      totalsteps: 2,
      icons: {
        mdiAccountTie,
        mdiPencil,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiPhone,
        mdiPhoneRemove,
        mdiAccountMultiple,
      },
    }
  },
  methods: {
    getVendors: function () {
      this.$store
        .dispatch(`vendor/editVendor`, {
          vendor_id: this.$route.params.id,
        })
        .then(response => {
          ;(this.vendor = this.fetchVendors), response
        })
    },

    updateVendor: function () {
      this.$store
        .dispatch(`vendor/updateNewVendor`, {
          vendor_id: this.$route.params.id,
          data: this.vendor,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record updated successfully',
          })
          this.$router.push({ name: 'vendor' })
        })
    },

    addphone: function () {
      this.vendor.phones.push({
        phone_id: '',
        type: '',
        module_name: 'vendor',
        tel_number: '',
        default: '',
      })
    },
    removePhone: function (index1) {
      this.vendor.phones.splice(index1, 1)
    },
  },

  computed: {
    ...mapGetters({
      fetchVendors: 'vendor/fetchVendors',
    }),
  },
}
</script>
  
  <style lang="scss" scoped>
</style>